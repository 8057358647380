<template>
  <div>
    <div class="page-heading relative">
      <div class="row">
        <div class="col-sm-12">
          <div class="heading-content">
            <div class="heading-title" style="padding: 0px;">
              <h2>Configurações</h2>
            </div>
          </div>
        </div>
      </div>
      <div class="header-line" style="top: 70px;"></div>
      <a class="btn btn-default pull-right ml-5 inherit btn-heading btn-sm btn-back-settings"
        style="margin-top: 0px !important;" type="button" @click="$router.go(-1)"><span><i
            class="mdi mdi-arrow-left"></i></span> Voltar</a>
      <a class="btn btn-primary pull-right ml-5 inherit btn-heading btn-sm btn-back-settings"
        v-if="this.tabActive == 'category'" style="margin-top: 0px !important;" type="button"
        @click="createCategory('create-category-modal')"><span><i class="mdi mdi-plus-circle-outline"></i></span>
        Adicionar categoria</a>
    </div>
    <div class="row">
      <div class="col-md-3">
        <div class="settings-menu">
          <nav class="navbar">
            <ul class="nav navbar-nav">
              <li :class="{ active: this.tabActive == 'profile' }">
                <a class="c-pointer" @click="tabActive = 'profile'"><span><i class="mdi mdi-account"></i></span>
                  Perfil</a>
              </li>
              <li :class="{ active: this.tabActive == 'company' }">
                <a class="c-pointer" @click="tabActive = 'company'"><span><i class="fa fa-building"></i></span>
                  Empresa</a>
              </li>
              <li :class="{ active: this.tabActive == 'category' }">
                <a class="c-pointer" @click="tabActive = 'category'"><span><i class="mdi mdi-file-tree"></i></span>
                  Categorias</a>
              </li>
              <li :class="{ active: this.tabActive == 'security' }">
                <a class="c-pointer" @click="tabActive = 'security'"><span><i class="mdi mdi-lock"></i></span>
                  Segurança</a>
              </li>
            </ul>
          </nav>
        </div>
      </div>
      <div class="col-md-9">
        <div class="card radius-10">
          <div class="card-body p-zero" style="padding: 20px !important">
            <div class="tab-content settings">
              <div class="row">
                <div class="col-md-12">
                  <div id="profile" class="tab-pane fade in" :class="{ active: this.tabActive == 'profile' }">
                    <h3>Perfil</h3>
                    <h4>ID Suporte: #{{ user.id }}</h4>
                    <p class="text-muted text-thin">
                      Atualize as suas informações pessoais
                    </p>
                    <form class="simcy-form ng-pristine ng-valid" action="/settings/update/profile/"
                      data-parsley-validate="" loader="true" method="POST" enctype="multipart/form-data" novalidate="">
                      <div class="form-group">
                        <div class="row">
                          <div class="col-md-6">
                            <label>Foto</label>
                            <label class="croppie-cabinet croppie-QPFWATFVA0NZ8RJN">
                              <div class="croppie-figure">
                                <img :src="info.avatar ? info.avatar : `/assets/images/avatar.png`"
                                  class="croppie-output" id="cropPreview" style="height: auto">
                                <div class="croppie-cabinet-overlay">
                                  <div class="croppie-upload-icon" @click="
                                    callCrop(
                                      'cropPreview',
                                      500,
                                      280,
                                      user.picture,
                                      'userPicture',
                                    )"></div>
                                </div>
                              </div>
                            </label>
                          </div>
                          <div class="col-md-6">
                            <br>
                            <div class="form-group">
                              <div class="row">
                                <div class="col-md-12">
                                  <label>Nome</label>
                                  <input type="text" class="form-control" name="fname" v-model="user.fname"
                                    placeholder="Nome" required="" />
                                </div>
                                <div class="col-md-12">
                                  <label>Email</label>
                                  <input type="email" class="form-control" name="email" v-model="user.email"
                                    placeholder="Email" required="" />
                                </div>
                                <div class="col-md-12">
                                  <label>Celular</label>
                                  <input type="text" class="form-control" name="phone" v-mask="'(##) #####-####'"
                                    v-model="user.phone" placeholder="Celular" />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="form-group">
                        <div class="row">
                          <div class="col-md-12 text-right">
                            <hr />
                            <button class="btn btn-primary" type="button" @click="updateInfo"
                              :class="{ 'qt-loader qt-loader-mini qt-loader-right': isSending }" :disabled="isSending">
                              Salvar
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                  <div id="company" class="tab-pane fade in" :class="{ active: this.tabActive == 'company' }">
                    <h3>Empresa</h3>
                    <p class="text-muted text-thin">Atualize as informações da sua empresa</p>
                    <span class="form-info text-right block">* São campos obrigatórios</span>
                    <form class="simcy-form ng-pristine ng-valid" action="/settings/update/company/"
                      data-parsley-validate="" loader="true" method="POST" enctype="multipart/form-data" novalidate="">
                      <input type="hidden" name="companyid" v-model="company.id" required="" autocomplete="off">
                      <input type="hidden" class="form-control ibge_m" name="ibge_m" v-model="company.ibge_m" autocomplete="off">
                      <div class="form-group">
                        <div class="row">
                          <div class="col-md-6">
                            <label>Logo</label>
                            <label class="croppie-cabinet croppie-VW8MBQ1WLV8W3E3L">
                              <div class="croppie-figure">
                                <img :src="company.logo ? company.logo : `/assets/images/avatar.png`"
                                  class="croppie-output" id="cropPreviewCompany" style="height: auto">
                                <div class="croppie-cabinet-overlay">
                                  <div class="croppie-upload-icon" @click="
                                    callCrop(
                                      'cropPreviewCompany',
                                      500,
                                      280,
                                      company.logo,
                                      'compayLogo',
                                    )"></div>
                                </div>
                              </div>
                            </label>
                          </div>
                          <div class="col-md-6">
                            <div class="form-group">
                              <div class="row">
                                <div class="col-md-12">
                                  <label>Nome fantasia / Marca</label>
                                  <input type="text" required="" class="form-control" name="name" v-model="company.name"
                                    placeholder="Nome">
                                </div>
                              </div>
                            </div>
                            <div class="form-group">
                              <div class="row">
                                <div class="col-md-12">
                                  <label>CNPJ*</label>
                                  <input type="text" required="" class="form-control cnpj" name="tax_id"
                                  v-model="company.tax_id" placeholder="CNPJ" maxlength="18" v-mask="'##.###.###/####-##'">
                                </div>
                              </div>
                            </div>
                            <div class="form-group">
                              <div class="row">
                                <div class="col-md-6">
                                  <label>Telefone</label>
                                  <input type="text" required="" class="form-control phone_number" name="phone_number"
                                    v-model="company.phone_number" placeholder="Telefone" maxlength="15" v-mask="'(##) #####-####'">
                                </div>
                                <div class="col-md-6">
                                  <label>Email</label>
                                  <input type="text" required="" class="form-control" name="email"
                                  v-model="company.email" placeholder="Email">
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <hr>
                      <div class="form-group">
                        <div class="row">
                          <div class="col-md-4">
                            <label>CEP*</label>
                            <input type="text" required="" class="form-control cep" name="cep" v-model="company.cep"
                              placeholder="Cep" maxlength="9" v-mask="'#####-###'">
                          </div>
                          <div class="col-md-4">
                            <label>Cidade*</label>
                            <v-select :options="cities" label="name" v-model="company.city"  :reduce="(option) => option.name" class="form-control form-select"
                              @search="searchCity"
                            ></v-select>
                            <input type="hidden" v-model="company.ibge_m" name="ibge_m" class="ibge_m" autocomplete="off">
                          </div>
                          <div class="col-md-4">
                            <label>Estado*</label>
                            <input type="hidden" name="ibge_uf"  v-model="company.ibge_uf" autocomplete="off">
                            <select id="state" name="state" v-model="company.state" class="select2 state form-control">
                              <option value="AC">Acre</option>
                              <option value="AL">Alagoas</option>
                              <option value="AP">Amapá</option>
                              <option value="AM">Amazonas</option>
                              <option value="BA">Bahia</option>
                              <option value="CE">Ceará</option>
                              <option value="DF">Distrito Federal</option>
                              <option value="ES">Espírito Santo</option>
                              <option value="GO">Goiás</option>
                              <option value="MA">Maranhão</option>
                              <option value="MT">Mato Grosso</option>
                              <option value="MS">Mato Grosso do Sul</option>
                              <option value="MG">Minas Gerais</option>
                              <option value="PA">Pará</option>
                              <option value="PB">Paraíba</option>
                              <option value="PR">Paraná</option>
                              <option value="PE">Pernambuco</option>
                              <option value="PI">Piauí</option>
                              <option value="RJ">Rio de Janeiro</option>
                              <option value="RN">Rio Grande do Norte</option>
                              <option value="RS">Rio Grande do Sul</option>
                              <option value="RO">Rondônia</option>
                              <option value="RR">Roraima</option>
                              <option value="SC">Santa Catarina</option>
                              <option value="SP">São Paulo</option>
                              <option value="SE">Sergipe</option>
                              <option value="TO">Tocantins</option>
                            </select>
                          </div>
                        </div>
                      </div>
                      <div class="form-group">
                        <div class="row">
                          <div class="col-md-12">
                            <label>Logradouro*</label>
                            <input type="text" required="" class="form-control logradouro" name="logradouro"
                              v-model="company.logradouro" placeholder="Logradouro">
                          </div>
                        </div>
                      </div>
                      <div class="form-group">
                        <div class="row">
                          <div class="col-md-4">
                            <label>Número*</label>
                            <input type="text" required="" class="form-control number" name="number" v-model="company.number"
                              placeholder="Número">
                          </div>
                          <div class="col-md-4">
                            <label>Complemento</label>
                            <input type="text" class="form-control complement" name="complement" v-model="company.complement"
                              placeholder="Complemento">
                          </div>
                          <div class="col-md-4">
                            <label>Bairro*</label>
                            <input type="text" required="" class="form-control bairro" name="district"
                              v-model="company.district" placeholder="Bairro">
                          </div>
                        </div>
                      </div>
                      <hr>
                      <div class="form-group">
                        <div class="row">
                          <div class="col-md-12">
                            <h4>Dados para emitir a nota fiscal</h4>
                          </div>
                          <div class="col-md-3">
                            <label>Inscrição Estadual</label>
                            <input type="text" class="form-control IE" name="IE" v-model="company.IE"
                              placeholder="Inscrição Estadual">
                            <input type="checkbox" id="isento" name="isento" v-model="company.isento" @change="changeIsento">
                            <label for="isento" style="margin-top: 10px;">IE Isento</label>
                          </div>
                          <div class="col-md-3">
                            <label>Inscrição Municipal</label>
                            <input type="text" class="form-control" name="IM" v-model="company.IM"
                              placeholder="Inscrição Municipal">
                          </div>
                          <div class="col-md-3">
                            <label>CNAE</label>
                            <input type="text" class="form-control" name="CNAE" v-model="company.CNAE" placeholder="CNAE">
                          </div>
                          <div class="col-md-3">
                            <label>Série da Nota</label>
                            <input type="text" class="form-control" name="serie" v-model="company.serie" placeholder="Série da Nota">
                          </div>
                        </div>
                      </div>
                      <div class="form-group">
                        <div class="row">
                          <div class="col-md-4">
                            <label>Código de Regime Tributário</label>
                            <select class="select2 form-control" name="CRT" v-model="company.CRT">
                              <option value="1">1 - Simples Nacional</option>
                              <option value="2">2 - Simples Nacional, excesso sublimite de receita bruta</option>
                              <option value="3">3 - Regime normal</option>
                            </select>
                          </div>
                          <div class="col-md-3">
                            <label>Nr. Próxima Nota</label>
                            <input type="text" class="form-control" name="nNF" v-model="company.nNF" placeholder="Número da nota">
                          </div>
                          <div class="col-md-2">
                            <label>Série RPS</label>
                            <input type="text" class="form-control" name="rps_serie" v-model="company.rps_serie" placeholder="Série RPS">
                          </div>
                          <div class="col-md-3">
                            <label>Nr. Próxima RPS</label>
                            <input type="text" class="form-control" name="rps_number" v-model="company.rps_number"
                              placeholder="Número da nota">
                          </div>
                        </div>
                      </div>
                      <hr>
                      <div class="form-group">
                        <div class="row">
                          <div class="col-md-12">
                            <h4>Dados para emitir a nota de consumidor</h4>
                          </div>
                          <div class="col-md-6">
                            <label>CSC</label>
                            <input type="text" class="form-control" name="CSC" v-model="company.CSC" placeholder="CSC">
                          </div>
                          <div class="col-md-6">
                            <label>CSC ID</label>
                            <input type="text" class="form-control" name="CSCid" v-model="company.CSCid" placeholder="CSC ID">
                          </div>
                        </div>
                      </div>
                      <div class="form-group">
                        <div class="row">
                          <div class="col-md-12 text-right">
                            <hr />
                            <button class="btn btn-primary" type="submit"
                              :class="{ 'qt-loader qt-loader-mini qt-loader-right': isSending }" :disabled="isSending">
                              Salvar
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                  <div id="categories" class="tab-pane fade in" :class="{ active: this.tabActive == 'category' }">
                    <h3>Categorias</h3>
                    <div class="row">
                      <div class="mini-tab-root tab-elevation1 tab-rounded"
                        style="margin: 10px; width:320px !important; float:right;">
                        <div class="mini-tab-content">
                          <div class="mini-tab-scroller mini-tab-fixed" style="overflow: hidden;">
                            <div class="mini-tab-flex">
                              <button style="width: 160px;"
                                class="mini-tab-button mini-tab-button-root mini-tab-button-root-color mini-tab-b"
                                :class="miniTab.tab == 0 ? 'mini-tab-selected' : ''" tabindex="0" type="button"
                                role="tab" aria-selected="true" @click="changeTab(0, 0)">
                                <span>
                                  Despesas
                                </span>
                                <span class="MuiTouchRipple-root"></span>
                              </button>
                              <button style="width: 160px;"
                                class="mini-tab-button mini-tab-button-root mini-tab-button-root-color mini-tab-b"
                                :class="miniTab.tab == 1 ? 'mini-tab-selected' : ''" tabindex="-1" type="button"
                                role="tab" aria-selected="false" @click="changeTab(1, 160)">
                                <span>
                                  Receitas
                                </span>
                                <span class="MuiTouchRipple-root"></span>
                              </button>
                            </div>
                            <span class="mini-tab-indicator"
                              :style="`width: 160px; height: 100%; z-index: 1; border-radius: 25px; left: ${miniTab.indicator}px;`"></span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <hr>
                    <div class="row">
                      <div class="col-md-12">
                        <div v-if="isLoading">
                          <div class="row">
                            <div class="col-sm-9">
                              <div class="shimmerBG title-line"
                                style="width: 60px; margin-top:5px; height: 60px; float:left; border-radius: 50%;">
                              </div>
                              <div class="shimmerBG title-line"
                                style="width: 90px; margin-top:25px; height: 20px; float: left;margin-left: 8px;border-radius: 0%;">
                              </div>
                            </div>
                            <div class="col-sm-3" style="padding-left: 70px;">
                              <div class="shimmerBG title-line"
                                style="width: 50px; margin-top:15px; height: 35px; float: left;"></div>
                              <div class="shimmerBG title-line"
                                style="width: 50px; margin-top:15px; height: 35px; float: left; margin-left: 8px;">
                              </div>
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-sm-9">
                              <div class="shimmerBG title-line"
                                style="width: 60px; margin-top:5px; height: 60px; float:left; border-radius: 50%;">
                              </div>
                              <div class="shimmerBG title-line"
                                style="width: 90px; margin-top:25px; height: 20px; float: left;margin-left: 8px;border-radius: 0%;">
                              </div>
                            </div>
                            <div class="col-sm-3" style="padding-left: 70px;">
                              <div class="shimmerBG title-line"
                                style="width: 50px; margin-top:15px; height: 35px; float: left;"></div>
                              <div class="shimmerBG title-line"
                                style="width: 50px; margin-top:15px; height: 35px; float: left; margin-left: 8px;">
                              </div>
                            </div>
                          </div>
                        </div>
                        <ul class="list-category" v-if="!isLoading">
                          <li v-for="(item, i) in categories" :key="i">
                            <div class="relative">
                              <div class="row">
                                <div class="col-sm-8 col-sm-8 col-lg-8 col-xs-8">
                                  <span class="chart-legend-item relative"
                                    style="border:none; margin:0px; padding:0px;">
                                    <div class="icon-category-overview"
                                      :style="`margin-right:0px;background: ${item.color}`">
                                      <img :src="`/assets/images/icons/png/${item.icon}.png`" />
                                    </div>
                                    &nbsp;&nbsp;{{ item.name }}
                                  </span>
                                </div>
                                <div class="col-sm-4 col-sm-4 col-lg-4 col-xs-4 text-right">
                                  <button type="button" class="btn-edit-category" v-modal="{
          target: 'edit-category-modal',
          data: item,
        }">
                                    <span>
                                      <i class="fa fa-cog"></i>
                                    </span>
                                  </button>
                                  <button type="button" class="btn-remove-category" @click="removeCategory(item)">
                                    <span>
                                      <i class="fa fa-trash"></i>
                                    </span>
                                  </button>
                                </div>
                              </div>
                              <div v-for="(childrem, i) in item.childrens" :key="i">
                                <div class="row relative" style="margin-left: 20px;" v-if="!childrem.is_parent">
                                  <div class="col-sm-8 col-sm-8 col-lg-8 col-xs-8">
                                    <span class="chart-legend-item relative"
                                      style="border:none; margin:0px; padding:0px;">
                                      <div class="icon-category-overview"
                                        :style="`margin-right:0px;background: ${childrem.color}`">
                                        <img :src="`/assets/images/icons/png/${childrem.icon}.png`" />
                                      </div>
                                      &nbsp;&nbsp;{{ childrem.name }}
                                    </span>
                                  </div>
                                  <div class="col-sm-4 col-sm-4 col-lg-4 col-xs-4 text-right">
                                    <button type="button" class="btn-edit-category" v-modal="{
          target: 'edit-category-modal',
          data: childrem,
        }">
                                      <span>
                                        <i class="fa fa-cog"></i>
                                      </span>
                                    </button>
                                    <button type="button" class="btn-remove-category" @click="removeCategory(childrem)">
                                      <span>
                                        <i class="fa fa-trash"></i>
                                      </span>
                                    </button>
                                  </div>
                                  <div class="line"
                                    style="position: absolute; left: 0; width: 10px; background: rgb(219, 219, 219); height: 1px; top: 35px;">
                                  </div>

                                </div>
                              </div>
                              <div class="line" v-if="item.childrens.length > 1"
                                style="position: absolute; left: 18px; width: 1px;background: #dbdbdb; height: 100%; top: 55px;">
                              </div>
                            </div>
                          </li>
                        </ul>
                        <div class="row">
                          <div class="col-sm-12">
                            <br><br>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div id="security" class="tab-pane fade in" :class="{ active: this.tabActive == 'security' }">
                    <h3>Segurança</h3>
                    <p class="text-muted text-thin">Atualize a sua senha qui</p>
                    <form class="simcy-form ng-pristine ng-valid" data-parsley-validate="" loader="true" method="POST"
                      novalidate="">
                      <div class="form-group">
                        <div class="row">
                          <div class="col-md-12">
                            <label>Senha atual</label>
                            <input type="password" class="form-control" v-model="user.password"
                              placeholder="Senha atual" />
                          </div>
                        </div>
                      </div>
                      <div class="form-group">
                        <div class="row">
                          <div class="col-md-12">
                            <label>Nova senha</label>
                            <input type="password" class="form-control" name="new_password" v-model="user.new_password"
                              placeholder="Nova senha" />
                          </div>
                        </div>
                      </div>
                      <div class="form-group">
                        <div class="row">
                          <div class="col-md-12 text-right">
                            <hr />
                            <button class="btn btn-primary" type="button" @click="updateInfo(true)"
                              :class="{ 'qt-loader qt-loader-mini qt-loader-right': isSending }" :disabled="isSending">
                              Salvar
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-sm-12"><br></div>
    </div>
    <edit-category-modal id="edit-category-modal" @save="request" />
    <m-crop id="m-crop" />
  </div>
</template>

<script>
// @ is an alias to /src
// @ is an alias to /src
import EditCategoryModal from '@/components/EditCategoryModal.vue';
import Swal from 'sweetalert2';

import AppService from "@/services/app.service";
import BucketService from "@/services/bucket.service";
import moment from 'moment-timezone';
import MCrop from '@/components/MCrop.vue';

export default {
  name: "Home",
  components: {
    EditCategoryModal,
    MCrop,
  },
  computed: {
    classes() {
      return ["wizard"];
    },
    info() {
      return JSON.parse(localStorage.getItem("stimper_user"));
    },
  },
  data() {
    return {
      tabActive: "profile",
      categories: [],
      isLoading: true,
      isSending: false,
      categoryType: 'expense',
      from: null,
      to: null,
      user: {},
      company: {
        CRT: 1,
      },
      monthStart: 0,
      miniTab: {
        tab: 0,
        indicator: 0,
      },
      cities: [],
      cropPreview: null,
      cropActive: {},
      indexFile: 0,
      file: null,
    };
  },
  provide: {},
  mounted() {
    window.scrollTo(0, 0);
    this.monthStart = new Date().getMonth();
  },
  created() {
    this.monthStart = new Date().getMonth();
    this.getUserInfo();
    this.request();
    this.$root.$on('call.crop', this.crop);
    this.$root.$on('save.category', this.request);
    this.$root.$on('option:selected', this.changeCity);
  },
  methods: {
    changeCity(value) {
      console.log(value);
    },
    searchCity(search, loading) {
      loading(true)
      AppService.getCity({q: search}).then(
        (response) => {
          this.cities = response.cities;
          loading(false)
        },
        (error) => {
          console.log(error);
        }
      );
    },
    changeIsento() {
      if (this.company.isento) {
        this.company.IE = 'ISENTO';
      } else {
        this.company.IE = '';
      }
    },
    callBackProgress(progress) {
      console.log(progress);
    },
    crop(data) {
      if (data) {
        this.file = data;
        document.getElementById(this.cropPreview).src = `${this.file.url
          }`;
      }

      const formData = new FormData();
      const filesCrop = [];
      filesCrop.push({
        x: this.file.x,
        y: this.file.y,
        w: this.file.width,
        h: this.file.height,
      });
      formData.append('files[]', this.file.file);
      formData.append('role', 'public');
      formData.append('folder', 'profile');
      formData.append('crop', JSON.stringify(filesCrop));
      console.log(this.file);

      BucketService.deleteAll().then(
        () => {
          BucketService.upload(
            formData,
            true,
            this.callBackProgress
          ).then(
            (response) => {
              AppService.updateAvatar({
                'url': response.data[0].url,
              }).then(
                (responseAvatar) => {
                  localStorage.setItem('stimper_user', JSON.stringify(responseAvatar.data.user));
                  this.$toast.show({
                    title: "Sucesso",
                    content: "Foto atualizadas!",
                    type: "success",
                  });
                },
                (error) => {
                  this.isSending = false;
                  console.log(error.response);
                  this.$toast.show({
                    title: "Erro",
                    content: error.response.data.data.message,
                    type: "error",
                  });
                },
              );
            },
            (error) => {
              this.isSending = false;
              console.log(error.response);
            },
          );
        },
        (error) => {
          this.isSending = false;
          console.log(error.response);
        },
      );
    },
    callCrop(
      cropPreview,
      maxWidthP,
      maxHeightP,
      cropActive,
      nameP = null,
      index = 0,
    ) {
      this.cropPreview = cropPreview;
      this.cropActive = cropActive;
      this.indexFile = index;
      this.$root.$emit('show.modal', 'm-crop');
      this.$store.dispatch('crop/config', {
        name: nameP,
      });
    },
    updateInfo(isPass = false) {
      this.isSending = true;
      if (!isPass) {
        this.user.password = null;
      }
      AppService.updateUserInfo(this.user).then(
        (response) => {
          this.isSending = false;
          console.log(response.data);
          this.user.password = null;
          this.user.new_password = null;
          localStorage.setItem('stimper_user', JSON.stringify(response.data.user));
          this.$toast.show({
            title: "Sucesso",
            content: "Informações atualizadas!",
            type: "success",
          });
        },
        (error) => {
          this.isSending = false;
          console.log(error.response);
          this.$toast.show({
            title: "Erro",
            content: error.response.data.data.message,
            type: "error",
          });
        },
      );
    },
    getUserInfo() {
      AppService.getUserInfo().then(
        (response) => {
          console.log('User info:');
          console.log(response);
          this.user = response.data;
          this.user.password = null;
          this.user.new_password = null;
        },
        (error) => {
          console.log(error);
          this.content = error;
          this.isSending = false;
          this.$toast.show({
            title: "Erro",
            content: "Erro interno, por favor tente novamente mais tarde",
            type: "error",
          });
        }
      );
    },
    deleteCategory(id) {
      AppService.deleteCategory(id).then(
        (response) => {
          this.isSending = false;
          console.log(response);
          this.$toast.show({
            title: "Sucesso",
            content: "Categoria removida!",
            type: "success",
          });
          this.request();
        },
        (error) => {
          console.log(error);
          this.content = error;
          this.isSending = false;
          this.$toast.show({
            title: "Erro",
            content: "Erro interno, por favor tente novamente mais tarde",
            type: "error",
          });
        }
      );
    },
    removeCategory(category) {
      Swal.fire({
        title: `Remover ${category.name}`,
        text: 'Tem certeza que deseja remover essa categoria?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Sim',
      }).then((result) => {
        if (result.value) {
          this.deleteCategory(category.id);
        }
      });
    },
    request() {
      this.isLoading = true;
      const tab = this.$route.query.tab;
      if (tab) {
        switch (tab) {
          case 'category':
            this.tabActive = 'category';
            break;
          default:
            break;
        }
      }
      AppService.getCategoriesHide(this.categoryType).then(
        (response) => {
          this.categories = response.categories;
          console.log("Categories");
          console.log(response);
          this.isLoading = false;
        },
        (error) => {
          console.log(error);
          this.content = error;
        }
      );
    },
    changeTab(tab, indicator) {
      this.miniTab.tab = tab;
      this.miniTab.indicator = indicator;
      this.categories = [];
      switch (tab) {
        case 0:
          this.categoryType = 'expense';
          break;
        case 1:
          this.categoryType = 'income';
          break;
        default:
          break;
      }
      this.request();
    },
    navigateDateTo(month) {
      console.log(month);
      this.from = `${moment(new Date()).format('YYYY')}-${month.id}-01`;
      this.to = moment(this.from, "YYYY-MM-DD").endOf('month');
      this.request();
    },
    changeDate(date) {
      console.log(date);
      if (date) {
        this.from = moment(date[0], 'DD/MM/YYYY').format(
          'YYYY-MM-DD',
        );
        this.to = moment(date[1], 'DD/MM/YYYY').format(
          'YYYY-MM-DD',
        );
        this.request();
      }
    },
    createCategory(id) {
      const data = {
        type: this.categoryType,
        color: '#7b93a4',
        icon: 'baby-buggy',
      };
      this.$root.$emit('show.modal', id, data);
    },
  },
};
</script>

<style scoped lang="scss">
.list-category {
  list-style: none;
  padding: 0px;
}

.list-category .row {
  padding-top: 15px;
  padding-bottom: 15px;
}

.btn-category-income {
  background-color: #e5ebee;
  color: #7b93a4;
  -webkit-box-shadow: none;
  box-shadow: none;
  position: relative;
  display: block;
  height: 34px;
  line-height: 34px;
  width: 100%;
  padding: 0 8px;
  border-radius: 4px;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
  outline: none;
  font-size: 14px;
  font-weight: 600;
  border: none;
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  white-space: nowrap;
  -webkit-transition: background-color .2s ease-in-out;
  -o-transition: background-color .2s ease-in-out;
  transition: background-color .2s ease-in-out;
  margin-top: 15px;
}

.btn-category-income-active {
  background: rgb(24, 178, 114);
  color: white;
}

.btn-category-income:hover {
  background-color: #d2d2d2;
}

.icon-category-overview {
  width: 40px;
  height: 40px;
  padding-top: 5px;
}

.btn-remove-category:hover {
  background-color: #f0d2d2;
}

.btn-edit-category span {
  display: inline-block;
  position: relative;
  top: -1px;
  color: #12c48b;
  text-align: center;
}

.btn-remove-category span {
  display: inline-block;
  position: relative;
  top: -1px;
  color: #f14c52;
  text-align: center;
}

.avatar-info {
  position: absolute;
  top: 0px;
  right: 10px;
}

.avatar-info img {
  width: 70px;
  height: 70px;
}
::v-deep .form-select .vs__dropdown-toggle{
  border: none;
}
</style>
